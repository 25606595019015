#contentSection{
    min-height: 100vh;
}

#imageWrapper{
    display: flex;
    margin-top: 10vh;
    height: 75vh;
    /* width: 70%; */
    justify-content: center;
    align-items: center;
}
#imageWrapper #homeImage{
    width:60%;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%,-50%);
    z-index: -4;
    text-align: right;
}
#homeImage img{
    width: 100%;
    animation: skew 35s infinite;
}
#homeContent p{
    font-size: 18px;
}
#homeContent span{
    display:block;
}
#homeContent{
    min-width: 400px;
    position: absolute;
    top: 50%;
    left: 35%;
    transform: translate(-50%,-50%);
}
#homeContent h2{
    margin-left: 120px;
    position: relative;
}
#homeContent h2:before{
    content: '';
    width: 85px;
    height: 3px;
    background: white;
    position: absolute;
    top: 28px;
    left: -120px;
}
.styles_type__2Teeh{
    background: -webkit-linear-gradient(60deg, #2b72ff, #241e90, #410d90, #2196d9);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    font-size: 48px;
    font-weight: 700;
}

#powered{
    margin-right: 65px;
    font-size: 11px;
    opacity: 0.9;
}

#circle1{
    position: absolute;
    top: 18vh;
    left: 20vw;
    width: 50px;
    animation: circle1 8s infinite;
}

@keyframes circle1 {
    0%, 100% {
        transform: translateY(0);
    }
    20% {
        transform: translateY(-5px);
    }
    40% {
        transform: translateY(-3px);
    }
    60% {
        transform: translateY(-10px);
    }
    80% {
        transform: translateY(5px);
    }
}
#circle2{
    filter: blur(4px);
    position: absolute;
    right: 10vw;
    top: 30vh;
    width: 20px;
    animation: circle2 15s infinite;
}

@keyframes circle2 {
    0%, 100% {
        transform: translateY(0) translateX(0px);
    }
    20% {
        transform: translateY(-5px) translateX(15px);
    }
    40% {
        transform: translateY(-3px) translateX(10px);
    }
    60% {
        transform: translateY(-10px) translateX(-15px);
    }
    80% {
        transform: translateY(5px) translateX(2px);
    }
}
#circle4{
    filter: blur(6px);
    position: absolute;
    left: 10vw;
    bottom: 20vh;
    width: 120px;
    animation: circle4 30s infinite;
}

@keyframes circle4 {
    0%, 100% {
      filter:blur(6px); transform: scale(1) translateY(0);
    }
    20% {
      filter:blur(8px); transform: scale(0.9) translateY(25px) ;
    }
    40% {
     filter:blur(3px); transform: scale(1.2)  translateY(-15px);
    }
    60% {
     filter:blur(8px); transform: scale(0.9)  translateY(-35px);
    }
    80% {
      filter:blur(1px); transform: scale(1.3) translateY(15px);
    }
}

#faqcircle1{
    filter: blur(0px);
    position: absolute;
    right: 10vw;
    bottom: -5vh;
    width: 30px;
    animation: circle2 10s infinite;
}
#faqcircle2{
    filter: blur(4px);
    position: absolute;
    left: -10vw;
    top: 5vw;
    width: 30px;
    z-index: -1;
    animation: circle5 10s infinite;
}

#wCircle2{
    filter: blur(1px);
    position: absolute;
    left: 20vw;
    top: 5vh;
    width: 30px;
    z-index: -2; 
   
}
#wCircle3{
    filter: blur(4px);
    position: absolute;
    right: 2vw;
    top: 35vh;
    width: 15px;
    z-index: -2; 
    animation: circle3 8s infinite;
}
#wCircle4{
    filter: blur(1px) ;
    position: absolute;
    left: 2vw;
    top: 15vh;
    width: 45px;
    z-index: -2; 
    animation: circle5 8s infinite;
}
#wCircle5{
    filter: blur(0) hue-rotate(80deg);
    position: absolute;
    right: 5vw;
    bottom: 40vh;
    width: 25px;
    z-index: 10000;
    animation: circle2 8s infinite;
}
#pcircle1{
    filter: blur(5px);
    position: absolute;
    right: 20vw;
    bottom: -5vh;
    width: 20px;
    z-index: -2;
}
#pcircle2{
    filter: blur(2px);
    position: absolute;
    left: 15vw;
    top: 2vw;
    width: 25px;
    z-index: -2;
    animation: circle5 8s infinite;

}
#pcircle3{
    filter: blur(0px);
    position: absolute;
    left: 13.5vw;
    top: 1.5vw;
    width: 13px;
    z-index: -1;
    animation: circle5 10s infinite;

}
#circle3{
    position: absolute;
    bottom: 30vh;
    width: 25px;
    right: 80px;
    filter: hue-rotate(80deg);
    animation: circle3 8s infinite;
}
@keyframes circle3 {
    0%, 100% {
        transform: translateY(0);
    }
    20% {
        transform: translateY(-8px);
    }
    40% {
        transform: translateY(3px);
    }
    60% {
        transform: translateY(10px);
    }
    80% {
        transform: translateY(-5px);
    }
}
#circle5{
    position: absolute;
    top: 45vh;
    width: 35px;
    right: 15vw;
    animation: circle5 8s infinite;
}
@keyframes circle5 {
    0%, 100% {
        transform: translateY(0);
    }
    20% {
        transform: translateY(5px);
    }

    60% {
        transform: translateY(-5px);
    }

}
#faq{
    margin-top: 15vh;
    position: relative;
    min-height: 50vh;
    margin-bottom: 25vh;
}
#feature #vectorR{
    width: 50%;
}
#feature #vectorL{
    width: 50%;
}
#vectorR{
    position: absolute;
    right: 0;
    top: 0;
    z-index: -105;
}

#vectorL{
    position: absolute;
    left: -10vw;
    bottom: 0;
    z-index: -105;
}

.pageTitle{
    font-size: 36px;
    font-weight: 700;
    text-align: center;
    margin-bottom: 60px;
}

#feature .pageTitle{
    margin-bottom: 15px;
}

.pageSubtitle{
    font-size: 16px;
    font-weight: 500;
    text-align: center;
    color: rgb(179, 180, 234);
    margin-bottom: 60px;
}
.faq-item{
    margin-top: 30px;
}
.faq-item svg{
    color: rgb(192, 45, 195);
    margin-right: 8px;
}
.faq-item h2{
    font-size: 15px;
    font-weight: 700;
}
.faq-item p{
    margin-top: 8px;
    font-size: 13px;
    font-weight: 400;
    opacity: 0.9;
}
#faq a{
    color: #6466ff !important;
}

.ScrollbarsCustom-Track {
    z-index: 1000000 !important;
    background: rgb(11 10 24) !important;
}
.ScrollbarsCustom-Thumb {
    background: rgb(29 27 43) !important;
}

.pointdown{
    width: 40px;
    height: 40px;
    border-radius: 100%;
    border: 1px solid white;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 50px;
    margin-bottom: -90px;
}

.pointdown svg{
    animation: bounce 2s infinite;
}



@keyframes bounce {
    0%, 20%, 50%, 80%, 100% {
      transform: translateY(0);
    }
    40% {
      transform: translateY(-5px);
    }
    60% {
      transform: translateY(-2px);
    }
}


#landingLoader{
    position: fixed;
    top: -100vh;
    left: 0;
    width: 100vw;
    height: 100vh;
    z-index: 100000000;
    background: #01000a;
    animation: loader 0.7s ease-in-out ;
}
@keyframes loader {
   0%{
    top: 0;
   }
   75%{
    top: -40vh;
   }
   90%{
    top: -80vh;
   }
   100%{
    top: -100vh;
   }
}
@keyframes skew {
   0%{
    transform: skew(0deg, 0deg);
   }
   30%{
    transform: skew(-25deg, 5deg);
   }
   55%{
    transform: skew(10deg, 10deg);
   }
   80%{
    transform: skew(20deg, -10deg);
   }
   100%{
    transform: skew(0deg, 0deg);
  }
}


#pricing{
    margin-top: 15vh;
    position: relative;
    min-height: 60vh;
    margin-bottom: 25vh;
}
#why{
    margin-top: 15vh;
    position: relative;
    min-height: 60vh;
    margin-bottom: 25vh;   
}
#feature{
    margin-top: 15vh;
    position: relative;
    min-height: 60vh;
    margin-bottom: 25vh;   
}

.pricing-section{
    display: flex;  
}

.pricing-item{
    flex: 1;
    text-align: center;
    padding: 15px 50px;
    margin: 30px;
    background: linear-gradient(100deg,#0c0023 , #1c1331);
    border-radius: 15px;
    position: relative;
    transform: scale(1.04);
}
.pricing-item:first-child{
    margin-left: 0px ;
    background: #07030fe2;
    transform: none;
}
.pricing-item:last-child{
    margin-right: 0px ;
    background: #07030fe2;
    transform: none;
}
.pricing-image{
    height: 120px;
}
.pricing-image img{
    height: 100%;
}
.pricing-content h2{
    font-size: 24px;
    font-weight: 700;
}
.pricing-content p{
    font-size: 12px;
    opacity: 0.7;
    margin-top: 3px;
    margin-bottom: 30px;
}
.pricing-content li{
    font-size: 13px;
    font-weight: 600;
    margin-bottom: 5px;
}
.pricing-content{
    height: 230px;
}
#ft{
    position: absolute;
    top: -15px;
    left: -15px;
    width: 100px;
}
#fb{
    position: absolute;
    bottom: -15px;
    right: -15px;
    width: 100px;
}

.why-item #fb{
    width: 80px;
}
.why-item #ft{
    width: 80px;
}

.pricing-button button{
    color: white;
    width: 100%;
    background: -webkit-linear-gradient(60deg, #140093, #221382, #4b10a6, #215bbe);
    border-radius: 35px;
    font-size: 13px;
    font-weight: 700;
    border: none;
    padding: 11px;
    text-transform: uppercase;
    transition: 0.4s all;
}
.pricing-button button:hover{
    box-shadow: 1px 1px 15px #271365;
    color: white !important;
    transform: scale(1.06);
}
.pricing-btm{
    margin-bottom: 20px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
}
.pricing-price{
    min-height: 40px;
    margin-bottom: 20px;
}

.pricing-price span{
    font-size: 30px;
    font-weight: 700;
    color: #c997ff;
    display: block;
}
span small{
    font-size: 12px;
    font-weight: 400;
}
.pricing-item:nth-child(2) .pricing-content{
    height:180px;
}


#whySection{
    height: 500px;
    position: relative;
}

#whyBg{
    position: absolute;
    top: 0;
    left: 0;
    z-index: -5;
    width: 100%;
    animation: why 20s infinite;
}

@keyframes why {
    0%{
     transform: skew(0deg, 0deg);
    }
    30%{
     transform: skew(-25deg, 0deg);
    }
    55%{
     transform: skew(10deg, 0deg);
    }
    80%{
     transform: skew(20deg, 0deg);
    }
    100%{
     transform: skew(0deg, 0deg);
   }
 }

#whyContent{
    position: absolute;
    top: 60%;
    left: 50%;
    transform: translate(-50%,-50%);
}
#enleap{
    width: 900px;
}
#whyItemSection{
    display: flex;
    flex-wrap: wrap;
    padding-left: 0;
    justify-content: space-around ;
}
.why-item h2{
    font-size: 18px;
    font-weight: 700;
    margin-bottom: 10px;
}
.why-item p{
    font-size: 13px;
    font-weight: 400;
    opacity: 0.7;
}
.why-item{
    flex: 0 0 30%;
    background: linear-gradient(100deg,#01000b , #180c21);
    border: 1px solid #231438;
    padding: 15px;
    border-radius: 13px;
    margin-bottom: 30px;
    min-height: 180px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding-bottom: 30px;
    transition: 0.6s all;
    position: relative;
}
.why-item:hover{
    box-shadow: 2px 2px 25px #080530;
    border: 1px solid rgb(71, 11, 85);
}
.why-item img{
    position: absolute;
    right: 15px;
    width: 50px;
    bottom: 15px;
}


#Background{
    position: absolute;
    width: 70%;
    max-width: 100vw;
    margin: auto;
    z-index: -100;
    top: 50px;
    transform: rotateX(180deg);
}
.feature-section{
    margin-bottom: 150px;
}
.feature-section, .feature-section2{
    display: flex;
}
.rotate{
    animation: rotate 5s infinite;
}


@keyframes rotate {
    0%{
        transform: rotate(0deg);
    }
    60%{
        transform: rotate(360deg);
    }
    80%{
        transform: rotate(0deg);
    }
    100%{
        transform: rotate(0deg);
   }
 }
.feature-section .feature-left{
    flex: 0 0 40%;
    position: sticky;
    top: 150px;
    height: fit-content;
    padding-left: 30px;
    padding-top: 50px;
}
.feature-section .feature-left h1, .feature-section .feature-left p{
   text-align: left !important;
}
.feature-right h2{
    width: 300px;
    background: linear-gradient(45deg, #1c0e3e, transparent);
    padding: 10px 15px;
    font-size: 18px;
    font-weight: 600;
    border-radius: 6px;
    margin-bottom: 50px;
}
li.inactive{
    opacity: 0.5;
}
.feature-right svg{
    color: #563da6;
    font-size: 19px;
    margin-right: 9px;
    margin-top: -4px;
}
.feature-left svg{
    color: #9d3da6;
    font-size: 19px;
    margin-left: 9px;
    margin-top: -4px;
}
.feature-left h2{
    width: 300px;
    margin: auto;
    margin-right: 0;
    background: linear-gradient(45deg, transparent , #1a112e);
    padding: 10px 15px;
    font-size: 18px;
    font-weight: 600;
    border-radius: 6px;
    margin-bottom: 50px;
}
.feature-section .feature-right{
    flex: 0 0 60%;
    padding-left: 10%;
}
.feature-section2 .feature-left{
    flex: 0 0 60%;
    padding-right: 10%;
    text-align: right;
    
}
.feature-section li, .feature-section2 li{
    font-size: 14px;
    margin-bottom: 15px;
}
.feature-section2 .feature-right h1, .feature-section2 .feature-right p{
   text-align: right !important;
}
.feature-section2 .feature-right{
    flex: 0 0 40%;
    position: sticky;
    top: 150px;
    height: fit-content;
    padding-right: 30px;
    padding-top: 50px;
}


#lbox{
    height: 300px;
    position: absolute;
    left: 0;
    width: 150px;
    z-index: -01;
    top: 0;
    background: none;
    text-decoration: inherit;
    border: 10px solid;
    border-image-slice: 1 !important;
    border-width: 3px;
    border-image-source: linear-gradient(to left, #9a24d1, #1d0e7b);
}
.nav-social img{
    width: 18px;
}

#rbox{
    height: 300px;
    position: absolute;
    right: 0;
    width: 150px;
    z-index: -01;
    top: 0;
    background: none;
    text-decoration: inherit;
    border: 10px solid;
    border-image-slice: 1 !important;
    border-width: 3px;
    border-image-source: linear-gradient(to left, #0c0348, #8434e0);
}
.rocket{
    position: relative;
}
#rocketShadow{
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    filter:contrast(2) brightness(2) blur(3px);
}
.navButton {
    background: transparent;
    color: white;
}
#cur{
    z-index: 99999 !important;
}



@media (max-width: 800px) {

    #circle4, #circle5{
        display: none;
    }

    #homeContent{
        left: 50%;
    }

    #imageWrapper #homeImage{
        width: 100%;
    }
    #enleap{
        width: 650px;
    }
    #whyBg{
        top: 25%;
    }
    #why{
        margin-top: 5vh;
    }
    #feature{
        margin-top: 0;
    }
    .why-item{
        flex: 0 0 45%;
    }

    #contentSection{
        padding: 15px;
    }

    .feature-section .feature-left, .feature-section2 .feature-right{
        flex: 0 0 80%;
        position: relative;
        top: 0;
        min-height: 350px;
    }

    .feature-section .feature-right{
        flex: 0 0 100%;
        padding-left: 25%;
    }
    .feature-section2 .feature-left{
        order: 2;
        flex: 0 0 100%;
        padding-right:25%;
    }
    .feature-section2 .feature-right{
        margin: auto;
        margin-right: 0;
    }
    .feature-section, .feature-section2{
        flex-wrap: wrap;
    }
    .pricing-section{
        flex-wrap: wrap;
    }
    #wCircle4, #wCircle2{
        display: none;
    }
    .pricing-item{
        flex: 0 0 90%;
        margin: auto !important;
        margin-bottom: 50px !important;
    }
}



@media (max-width: 560px) {

    #contentSection{
        padding: 5px;
    }
    .feature-section .feature-left, .feature-section2 .feature-right{
        flex: 0 0 95%;
    }
    .feature-section .feature-right{
        padding-left: 0;
    }
    .feature-section2 .feature-left{
        padding-right: 0;
    }
    #imageWrapper #homeImage{
        text-align: center;
    }
    .pointdown{
        cursor: none;
        margin: auto;
        margin-bottom: -249px;
        margin-top: 100px;
    }
    #powered{
        margin-right: 0;
        font-size: 10px;
        opacity: 0.6;
    }
    #homeContent{
        min-width: 150px;
        width: 100%;
        text-align: center;
    }
    #homeContent h2:before{
        display: none;
    }
    #homeContent span{
        display: initial;
    }
    #homeContent h2{
        margin-left: 0px;
    }
    #enleap{
        width: 350px;
    }
    #whyBg{
        top: 30%;
    }
    #why, #feature{
        margin-top: 0;
    }
    .why-item{
        flex: 0 0 100%;
    }
    .why-item:last-child #ft{
        display: none;
    }
}





.HLUdz{
    cursor: initial !important;
}