 

/**************************************************************************************

                                        FONT FAMILY

**************************************************************************************/
  

@font-face {
    font-family: "Gilroy"; 
    font-style: normal; 
    font-weight: 500;
    src: url("../assets/fonts/Gilroy-Regular.ttf") format("truetype");
}
@font-face {
    font-family: "Gilroy"; 
    font-style: normal; 
    font-weight: 600;
    src: url("../assets/fonts/Gilroy-Medium.ttf") format("truetype");
}
@font-face {
    font-family: "Gilroy"; 
    font-style: bold; 
    font-weight: 700;
    src: url("../assets/fonts/Gilroy-Bold.ttf") format("truetype");
}
@font-face {
    font-family: "Gilroy"; 
    font-style: black; 
    font-weight: 800;
    src: url("../assets/fonts/Gilroy-ExtraBold.ttf") format("truetype");
}
@font-face {
    font-family: "Gilroy"; 
    font-style: black; 
    font-weight: 900;
    src: url("../assets/fonts/Gilroy-Black.ttf") format("truetype");
}
@font-face {
    font-family: "Gilroy"; 
    font-style: thin; 
    font-weight:300;
    src: url("../assets/fonts/Gilroy-Thin.ttf") format("truetype");
}
@font-face {
    font-family: "Gilroy"; 
    font-style: light; 
    font-weight:100;
    src: url("../assets/fonts/Gilroy-Light.ttf") format("truetype");
}






/**************************************************************************************

                                        GENERAL 

**************************************************************************************/
  
 
html, body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, img, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var,
b, u, i, center,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, embed, 
figure, figcaption, footer, header, hgroup, 
menu, nav, output, ruby, section, summary,
time, mark, audio, video {
    margin: 0;
    padding: 0;
    border: 0;
    vertical-align: baseline;
    box-sizing: border-box;
}
article, aside, details, figcaption, figure, 
footer, header, hgroup, menu, nav, section {
    display: block;
}
ol, ul {
    list-style: none;
}
blockquote, q {
    quotes: none;
}
blockquote:before, blockquote:after,
q:before, q:after {
    content: '';
    content: none;
}
table {
    border-collapse: collapse;
    border-spacing: 0;
}
a {
    text-decoration: none!important;
    color: #f3f3f3;
    transition: 0.5s all;
}
a:hover {
    color: #7620FF;
}
input:-webkit-autofill {
    -webkit-box-shadow:0 0 0 50px #fff inset; /* Change the color to your own background color */
    box-shadow: transparent;
    -webkit-text-fill-color: #fff;
}
body {
    background: #01000a;
    color: #ffffff;
    font-family: 'Gilroy', sans-serif;
    overflow-x: hidden;
    font-size: 16px;
    font-weight: 400;
}
.container {
    width: 100%;
    max-width: 1220px;
    margin: 0 auto;
    padding: 0px 30px;
}
@media only screen and (max-width: 859px) {
    .container {
        padding: 0 15px;
    }
}
.regular {
    font-weight: 500!important;
}
.bold {
    font-weight: 700!important;
}
.extraBold {
    font-weight: 900!important;
}
.light {
    font-weight: 300!important;
}
.thin {
    font-weight: 100!important;
}

.btn-primary{
    background: rgb(78,65,255);
    background: linear-gradient(46deg, rgb(21, 5, 255) 0%, rgba(114,219,253,1) 100%);
    width: 100%;
    padding: 15px;
    border: none;
    border-radius: 35px;
    outline: none;
    color: #fff;
    font-weight: 700;
    cursor: pointer;
    transition: 0.3s all;
}
.btn-primary:hover{
    transform: scale(1.08);
    box-shadow: 0px 0px 25px rgb(69, 126, 143);
}
.signOutButton{
    margin-left: 15px;
    font-size: 13px;
    transform: 0.4s all;
    display: none;
}
.signOutButton:hover{
    opacity: 0.5;
    color:white;
}
.navButton{
    position:relative;
    padding:12px 25px !important;
    font-size: 13px;
    margin:5px;
    display:inline-block;
    transition: 0.2s all;
}
.navButton::before {
    content:"";
    position:absolute;
    top:0;
    left:0;
    right:0;
    bottom:0;
    border-radius:50px; 
    padding:2px; 
    background:linear-gradient(60deg, #2b72ff, #241e90, #410d90, #2196d9);
    -webkit-mask: 
       linear-gradient(#fff 0 0) content-box, 
       linear-gradient(#fff 0 0);
    -webkit-mask-composite: xor; 
            mask-composite: exclude; 
}
.navButton:hover{
    transform: scale(1.01);
    color:white !important;
    box-shadow: 0px 0px 25px rgb(69, 126, 143);
    border-radius: 25px;
}

.sideNav{
    background: black;
    border-width: 3px;
    border-style: solid;
    border-image: linear-gradient( to bottom, #645ad9, rgba(0, 0, 0, 0) ) 1 100%;
    border-width: 0 0px 0px 4px;
}

/**************************************************************************************

                                        HELPERS 

**************************************************************************************/
  
.textCenter {
    text-align: center
}
.animate {
    transition: 0.3s ease;
}
.pointer {
    cursor: pointer;
}
.relative {
    position: relative;
}
.textCenter {
    text-align: center;
}
.textLeft {
    text-align: left;
}
.textRight {
    text-align: right;
}
.x100 {
    width: 100%;
}

.tag {
    padding: 5px 10px;
    margin: 10px 0;
}
.shadow {
    box-shadow: 0 0 11px rgba(237, 232, 237, 0.5)  ; 
    -webkit-box-shadow: 0 0 11px rgba(237, 232, 237, 0.5)  ; 
    -moz-box-shadow: 0 0 11px rgba(237, 232, 237, 0.5)  ; 
}

.ml-3 a{
    color: #959595 !important;
}
.ml-3 a.active {
    color: #6944ff !important;
    font-weight: 600;
}

/**************************************************************************************

                                        FLEXBOX 

**************************************************************************************/
  
.flex {
    display: flex;
}
.flexSpaceCenter {
    display: flex !important;
    justify-content: space-between;
    align-items: center;
}
.flexNullCenter {
    display: flex;
    align-items: center;
}
.flexColumn {
    flex-direction: column;
}
.flexCenter {
    display: flex;
    justify-content: center;
    align-items: center;
}
.flexSpaceNull {
    display: flex;
    justify-content: space-between;
}





/**************************************************************************************

                                        FONT SIZE

**************************************************************************************/


.font11 {
    font-size: 0.688rem;
}
.font12 {
    font-size: 0.75rem;
}
.font13 {
    font-size: 0.813rem;
}
.font14 {
    font-size: 0.875rem;
}
.font15 {
    font-size: 0.938rem;
}
.font18 {
    font-size: 1.125rem;
}
.font20 {
    font-size: 1.25rem;
}
.font25 {
    font-size: 1.563rem;
}
.font30 {
    font-size: 1.875rem;
}
.font40 {
    font-size: 2.5rem;
}
.font60 {
    font-size: 3.75rem;
    line-height: 4.5rem;
}


@media only screen and (max-width: 859px) {
    .font11 {
        font-size: 0.688rem;
    }
    .font12 {
        font-size: 0.688rem;
    }
    .font13 {
        font-size: 0.75rem;
    }
    .font14 {
        font-size: 0.813rem;
    }
    .font15 {
        font-size: 0.813rem;
    }
    .font18 {
        font-size: 0.938rem;
    }
    .font20 {
        font-size: 1.125rem;
    }
    .font25 {
        font-size: 1.25rem;
    }
    .font30 {
        font-size: 1.5rem;
    }
    .font40 {
        font-size: 1.875rem;
    }
    .font60 {
        font-size: 2.8rem;
    }
}

.ml-3{
    margin-right: 30px;
}
.mr-3{
    margin-left: 30px;
}
#notFound{
    position: absolute;
    top: 48%;
    left: 45%;
    font-size: 20px;
    text-shadow: 2px 2px 15px blue;
    font-weight: 700;
    animation: shake-animation 2s cubic-bezier(.36,.07,.19,.97) both infinite;
}
@keyframes shake-animation {
    10%, 90% {
        transform: translate3d(-15px, 0, 0);
    }
    20%, 80% {
        transform: translate3d(160px, 0, 0);
    }
    30%, 50%, 70% {
        transform: translate3d(-40px, 0, 0);
    }
    40%, 60% {
        transform: translate3d(40px, 0, 0);
    }
}