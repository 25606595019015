#launchpadContent{
    padding-top: 18vh;
    align-items: flex-start;
    padding-bottom: 25vh;
    position: relative;
}
#launchpadContent #leftSide{
    padding-left: 10%;
}
#l2{
    position: absolute;
    right: 0;
    filter: invert(1);
    width: 120px;
}
#l1{
    position: absolute;
    width: 80px;
    left: -50px;
    transform: rotate(-115deg);
    top: 80vh;
}
.tab-content{
    padding-top: 40px;
}
#launchpadContent #leftSide h2{
    max-width: 100%;
    opacity: 0.6;
    color:white;
    font-size: 16px;
    line-height: 27px;
    padding-bottom: 15px !important;
}
.tab-content>.active{
    border-bottom: none !important;
}

#launchpadContent #leftSide h1::after{
    display: none;
}

#launchpadContent .btnWrapper{
    display: flex;
    align-items: center;
    max-width: 100%;
}

.connectWallet small{
    display: block;
    font-weight: 100;
    font-size: 9px;
    opacity: 0.8;
}
.nav-tabs[role='tablist']{
    border: none !important;
}
.nav-tabs .nav-link[role='tab']{
    color: white;
    font-weight: 400;
    font-size: 18px;
    padding: 5px 30px !important;
    margin-right: 20px !important;
    outline: none !important;
    border: none !important;
    border-color: #3e3ad3 !important;
    opacity: 0.6;
}
.nav-tabs .nav-link[role='tab'].active{
    font-weight: 700;
    background: transparent;
    opacity: 1 !important;
    border: none;
    color: white;
    border-bottom: 2px solid #3e3ad3 !important
}
#launchpadContent .connectWallet{
    padding: 8px 20px;
    border-radius: 5px;
    margin: 0;
    font-size: 13px;
}
.mint-section{
    position: absolute;
    bottom: 10px;
    right: 15px;
}
@keyframes beat-animation {
    0%    {transform:scale(1);}
    25%   {transform:scale(1.02);}
    40%   {transform:scale(0.97);}
    75%   {transform:scale(1.05);}
    100%  {transform:scale(1);}
}

#topSpan{
    margin-top: 20px;
}
#topSpan span{
    border-radius: 5px;
    background: transparent;
    font-weight: 600;
    border-radius: 5px;
    border: 2px solid #6460ff;
    color: #6169ff;
    margin-bottom: 10px;
    display: inline-block;
}
#launchpadContent .btnWrapper span{
    font-size: 15px;
    font-weight: 700;
    opacity: 1;
}

#loader img{
    width: 100px;
}
#loader p{
    text-align: center;
    margin-top: 50px;
    font-size: 12px;
}
#loader{
    position: fixed;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    z-index: 1000;
    width: 100vw;
    height: 100vh;
    background-color: black;
}
#projectError{
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%,-50%);
    font-size: 20px;
    font-weight: 700;
    text-shadow: 2px 2px 15px #3333d7;
}
#projectError div{
    animation: b-animation 2s infinite;
}
@keyframes b-animation {
    0%    {transform:scale(1);}
    25%   {transform:scale(1.05);}
    40%   {transform:scale(0.9);}
    75%   {transform:scale(1.1);}
    100%  {transform:scale(1);}
}
#projectBack{
    position: absolute;
    margin-top: -60px;
    font-size: 24px;
}

.social-item{
    margin-top: 10px;
    padding-bottom: 40px;
}
.social-item img{
    width: 20px;
    padding-left: 0;
    transition: 0.4s all;

}
#enleapLogo{
    width: 25px;
    filter: brightness(5);
    margin-bottom: -3px;
}
.social-item a{
    padding-left: 0 !important;
    padding-right: 25px !important;
}
.social-item img:hover{
    transform: scale(1.15);
    opacity: 0.7;
}
@media (max-width: 960px) {
    #rightSide{
        flex: 2;
    }
    #leftSide {
        flex: 5;
    }
    .nav-tabs .nav-link[role='tab']{
        font-size: 16px !important;
        padding: 5px 15px !important;
        margin-right: 20px !important;
    }
    #home{
        flex-direction: column;
    }
    #leftSide h2{
        padding: 15px 0 50px 0;
        max-width: 100%;
    }
    #imageWrapper{
        width: 80%;
        justify-content: center;
        margin:auto;
    }
    #leftSide{
        width: 100%;
        order: 2;
        margin: 50px 0;
    }
    
    #rightSide{
        width: 100%;
        order: 1;
        margin-top: 30px;
    }
    .btnWrapper{
        margin: 0 auto;
    }
    #aboutSection{
        background-size: cover;
    }
}

@media (max-width: 800px) {
    #rightSide{
        position: relative !important;
    }
    #launchpadContent, #launchpadContent .btnWrapper{
        flex-direction: column;
    }
    #launchpadContent .btnWrapper span{
        margin-top: 25px;
        font-size: 15px;
    }
    #launchpadContent #leftSide{
        padding: 0px 5% !important;
    }
    #imageWrapper{
        width: 50%;
        margin-bottom: 100px;
    }
    #launchpadContent{
        padding-top: 2vh;
    }
}

@media (max-width: 560px) {
    #imageWrapper{
        width: 90%;
        margin-bottom: 100px;
    }
    #leftSide{
        margin: 80px 0 50px 0;
    }
    .nav-tabs .nav-link[role='tab']{

    }
    #bottomSpan{
        flex-wrap: wrap;
    }
    .spanItem{
        margin-bottom: 15px;
    }
    .mint-section{
        position: relative;
        bottom: 0;
        right: 0;
        margin-top: 10px;
        margin-bottom: 60px;
    }
    .mint-info-list .countdown{
        bottom: 15px !important;
        top: initial !important;
    }
    .countdown span{
        margin-bottom: 0 !important;
    }
}


#gif-container{
    overflow: hidden;
    
}
#gif-container img{
    width: 100%;
}


.custom-modal .modal-dialog{
    position: absolute!important;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%,-50%)!important;
    transform: translate(-50%,-50%)!important;
    margin: 0!important;
    width: 350px;
    height: 250px;
}
.promptClose {
    position: absolute;
    top: 20px;
    right: 20px;
    background: none;
    border: none;
    color: rgb(206, 206, 206);
}
.modal-content{
    height: 100%;
    border-radius: 15px !important;
    background: #0b0b16 !important;
    box-shadow: 0px 0px 15px #5c4cff;
    animation: backdrop 2s ease infinite;
}
.promptClose{
    font-weight: 900;
}
#l4{
    filter: brightness(5);
    width: 100px;
    margin-bottom: 20px; 
}
.hide{
    display: none !important;
}
@keyframes backdrop {
    0% {
        box-shadow: 0px 0px 15px #33305c;
    }
    50% {
        box-shadow: 0px 0px 65px #45435b;
    }
    100% {
        box-shadow: 0px 0px 15px #33305c;
    }
  }

.modal-body{
    display: flex;
    justify-content: center;
    align-items: center;
}
.modal-body p{
    color: #e2e2e2;
    font-size: 15px;
    text-align: center;
}
.modal-backdrop.show{
    opacity: 0.7 !important;
}
.prompt-modal .modal-body{
    padding: 25px 60px;
}
#promptMintBtn{
    border-radius: 5px;
    padding: 8px;
    font-size: 12px;
    margin-top: 10px;
}
.promptBody{
    text-align: center;
    display: flex;
    align-items: center;
}
#l5{
    width: 200px;
    margin: auto;
}
.promptBody p{
    font-size: 14px;
    font-weight: 700;
    text-align: left;
}
#mintQuantity{
    min-width: 200px;
    background-color: #2a2a3c !important;
    border: 1px solid #4b4b60 !important;
    margin-top: 5px;
    color: var(--default-font-color) !important;
}
.prompt-modal .modal-dialog{
    height: 300px !important;
    width: auto !important;
}

.fa-item {
    padding: 5%;
    background: rgba(0, 0, 0, 0.05);
    border-radius: 15px;
}

.fa-item img {
    width: 50px;
    margin-bottom: 15px;
}

.fa-item h3 {
    font-weight: 800;
    font-size: var(--header-font-size);
}

.fa-item p {
    font-size: var(--paragraph-font-size);
}

.fa-item button {
    display: block;
    margin-top: 15px;
    margin-bottom: 15px;
}

.fa-step {
    padding-left: 60px;
    position: relative;
    margin-top: 30px;
}

.fa-step .step-icon {
    position: absolute;
    width: 25px;
    height: 25px;
    background: #2116ce;
    text-align: center;
    border-radius: 100%;
    font-size: 13px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: white;
    font-weight: 600;
    left: 15px;
    top: -3px;
}
.form-control[type='file']{
    background-color: #2a2a3c !important;
    border: 1px solid #4b4b60 !important;
    color: var(--default-font-color) !important;
}
.fa-step h3 {
    font-size: 15px;
    font-weight: 500;
    opacity: 0.9;
    margin-bottom: 10px;
}
.fa-step p {
    font-size: var(--paragraph-font-size);
    opacity: 0.9;
}
.fa-step a {
    color: #0014a1;
    font-weight: 600;
}
.fa-step::after {
    content: "";
    position: absolute;
    width: 1px;
    height: 100%;
    background: #bfcaff;
    top: 22px;
    left: 27px;
}
.fa-step:last-child::after {
    background-color: transparent !important;
}





.fa-step {
    padding-left: 60px;
    position: relative;
    margin-top: 30px;
    padding-bottom: 30px;
}

.fa-step .step-icon {
    position: absolute;
    width: 25px;
    height: 25px;
    background: #2116ce;
    text-align: center;
    border-radius: 100%;
    font-size: 13px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: white;
    font-weight: 700;
    left: 15px;
    top: -3px;
}

.fa-step h3 {
    font-size: 18px;
    font-weight: 700;
    opacity: 0.9;
    margin-bottom: 0;
}
.fa-step p {
    font-size: var(--paragraph-font-size);
    opacity: 0.9;
    margin-bottom: 8px;
}
.fa-step a {
    color: #0014a1;
    font-weight: 600;
}
.fa-step::after {
    content: "";
    position: absolute;
    width: 1px;
    height: 100%;
    background: #4c4d5d;
    top: 22px;
    left: 27px;
}
.fa-step:last-child::after {
    background-color: #4c4d5d !important;
    height: 80% !important;
}




#roadmapContent h3{
    font-size: 18px;
    color: white;
    font-weight: 600;
    margin-bottom: 15px;
}
#roadmapContent p{
    font-size: 15px;
    line-height: 25px;
    opacity: 0.7;
    color: white;
}
#rightSide{
    position: sticky;
    top: 100px;
}
#teamContent .social{
    display: flex;
    margin-bottom: 15px;
}
#teamContent .social img{
    width: 18px;
    margin-right: 15px;
}
#teamContent .desc{
    font-size: 14px;
    color: white;
    opacity: 0.6;
    margin-bottom: 25px;
}
#teamContent .role{
    font-size: 12px;
    background: #1e19a8;
    color: white;
    border-radius: 4px;
    padding: 2px 10px;
}
#teamContent .name{
    font-size: 18px;
    font-weight: 700;
    margin-top: 7px;
    margin-bottom: 5px;
    color: white;
}
#teamContent h3{
    background: #18182a;
    font-size: 16px;
    font-weight: 600;
    padding: 25px;
    line-height: 25px;
    color: #d2d2d2;
    margin-bottom: 50px;
    border-radius: 5px;
}

#teamBg{
    background: linear-gradient(76deg, rgb(27 20 176) 0%, rgb(66 58 227) 31%, rgb(48 35 200) 59%, rgb(51 17 178) 98%) !important;
    color: white !important;
    position: relative;
    border-radius: 5px;
    margin-bottom: 50px;
    box-shadow: 2px 2px 30px #454569 !important;
    padding: 25px 40px;
    font-weight: 600;
}

.mint-info-list{
    border: 1px solid #2d2d47;
    margin-bottom: 15px;
    border-radius: 8px;
    padding: 10px 20px;
    position: relative;
}
.mint-info-list span{
    background: #252534;
    font-size: 11px;
    padding: 3px 8px;
    border-radius: 15px;
    color: #cbcbcb;
    font-weight: 700;
    margin-bottom: 25px;
    margin-right: 8px;
}
.mint-info-list span.wl{
    background: #2f925d;
    color: white;
}
.mint-info-list span.nwl{
    background-color: #ae3232;
}
.burn-item{
    border: 1px solid #2d2d47;
    margin-bottom: 15px;
    border-radius: 8px;
    padding: 10px 20px;
    position: relative;
}
.burn-item h4{
    font-size: 17px;
    font-weight: 700;
    color: white !important;
    opacity: 1;
    padding-bottom: 5px !important;
    padding-top: 15px;
}
.burn-item p{
    color: white;
    font-size: 14px;
    font-weight: 500;
    opacity: 0.7;
    margin-bottom: 50px;
}

.burn-item button{
    background: #3e3ad3;
    padding: 5px 15px;
    width: auto;
    font-size: 14px;
    font-weight: 600;
    border-radius: 6px;
    position: absolute;
    bottom: 15px;
    right: 15px;
}
.mint-info-list .mint-info-content {
    color: white;
    margin-top: 50px;
    font-weight: 400;
    font-size: 15px;
    color: rgb(206, 206, 206);
}
.mint-info-list label{
    padding-left: 8px;
    color: white !important;
    padding-right: 8px;
    font-weight: 800;
}
.mint-info-list b{
    
    padding: 0px 5px;

}
.countdown p{
    font-size: 14px;
    color: #4b33e7;
    font-weight: 700;
}
.mint-info-list .countdown{
    display: flex;
    position: absolute;
    top: 10px;
    right: 20px;
}

.countdown span{
    background: transparent;
    font-weight: 800;
    font-size: 14px;
    padding: 0;
    margin-left: 10px;
}


.custom-tooltip {
    position: relative;
    cursor: pointer;
}

.custom-tooltip .tooltiptext {
    visibility: hidden;
    min-width: 250px;
    max-width: 400px;
    width: max-content;
    background-color: #09090f;
    color: #fff;
    border-radius: 6px;
    padding: 10px 25px;
    position: absolute;
    z-index: 1;
    bottom: 100%;
    left: 50%;
    margin-left: -60px;
    font-size: 13px;
    /* Fade in tooltip - takes 1 second to go from 0% to 100% opac: */
    opacity: 0;
    transition: opacity 0.5s;
}

.custom-tooltip:hover .tooltiptext,  .tooltiptext:hover {
visibility: visible;
opacity: 1;
}
.tooltipTitle{
    font-size: 17px;
    font-weight: 700;
    color: white;
    margin-bottom: 5px;
    display: block;
}
.tooltipContent{
    opacity: 0.6;
    margin-bottom: 15px;
}
.tooltiptext a{
    width: 100%;
    text-align: right;
    display: block;
    color: #3f56ff;
}